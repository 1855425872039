import StatusCircle from "@components/StatusCircle";
import { Button, Icon } from "@components/library";
import FuelModal from "@components/modals/FuelModal";
import JoinWaitlistModal from "@components/modals/JoinWaitlistModal";
import { COLORS, FONTS } from "@constants";
import { useSearchParams } from "@hooks/router";
import useFollowRequest from "@hooks/useFollowRequest";
import { toggleLoginModal } from "@redux/actions/profileActions";
import { RootState } from "@redux/store";
import { getCanSubmitProposal } from "@requests/requests";
import { SegmentEventName } from "@tsTypes/__generated__/enums";
import { CompanyPartial, RfpPageRequest } from "@tsTypes/index";
import { RfpStatus } from "@tsTypes/rfps";
import { UserRole } from "@tsTypes/users";
import { track } from "@utils/appUtils";
import { setPrevUrlCookie } from "@utils/authUtils";
import { requestProposalUrlPath } from "@utils/requestUtils";
import { getRfpStatus } from "@utils/rfpUtils";
import { withoutTooltip } from "@utils/textUtils";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { SignUpModal } from "../WhiteLabel/SignUp";
import AddToCalendarButton from "./AddToCalendarButton";

const { ACTIVE, COMING_SOON, IN_EXTENSION, CLOSED } = RfpStatus;

interface Props {
  request: RfpPageRequest;
  asPreview: boolean;
  setIsShareModalOpen: (isOpen: boolean) => void;
}

const RfpCtaContainer = ({ request, asPreview, setIsShareModalOpen }: Props) => {
  const [isFuelModalOpen, setIsFuelModalOpen] = useState(false);
  const [isWhiteLabelSignUpModalOpen, setIsWhiteLabelSignUpModalOpen] = useState(false);
  const [isJoinWaitlistModalOpen, setIsJoinWaitlistModalOpen] = useState(false);

  const rfp = request.requestable;

  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);

  const dispatch = useDispatch();
  const history = useHistory();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("share") === "true") handleShare();
  }, []);

  const isWhiteLabel = location.pathname.startsWith("/partner");

  const handleSubmitClick = async () => {
    track(SegmentEventName.Click, {
      ui_component: "Submit Proposal Button",
    });

    const submitUrl = requestProposalUrlPath(request);

    if (isWhiteLabel) {
      if (currentUser.id) history.push(submitUrl);
      else setIsWhiteLabelSignUpModalOpen(true);

      return;
    }

    const isFuel =
      "company_name" in request.company && request.company.company_name?.includes("FUEL");

    if (currentUser.id) {
      if (isFuel) {
        const canSubmit = await getCanSubmitProposal(request.id);

        if (canSubmit) {
          history.push(submitUrl);
        } else {
          setIsFuelModalOpen(true);
        }
      } else {
        history.push(submitUrl);
      }
    } else {
      if (isFuel) {
        setPrevUrlCookie();
      } else {
        setPrevUrlCookie(submitUrl);
      }

      dispatch(
        toggleLoginModal({
          open: true,
          previousAction: "easy apply",
        })
      );
    }
  };

  const { handleFollowRequest, isUserFollowing } = useFollowRequest(request.id);

  const handleNotify = () => {
    track("Clicked follow RFP button", {
      isUniversityMember: currentUser.role === UserRole.UNIVERSITY_ADMIN,
    });

    handleFollowRequest();
  };

  const handleShare = () => {
    track("Clicked share RFP button");

    if (currentUser.id) {
      setIsShareModalOpen(true);
    } else if (isWhiteLabel) {
      setPrevUrlCookie();
      setIsWhiteLabelSignUpModalOpen(true);
    } else {
      dispatch(toggleLoginModal({ open: true, previousAction: "share rfp" }));
    }
  };

  const handleJoinWaitlist = () => {
    track("Clicked join waitlist button");
    setIsJoinWaitlistModalOpen(true);
  };

  const rfpStatus = getRfpStatus(request);

  let status: ReactNode = (
    <>
      <StatusCircle margin="0 8px 0 0" /> Deadline:{" "}
      {format(utcToZonedTime(rfp.deadline ?? 0, "America/New_York"), "MMMM d")}
    </>
  );
  if (rfpStatus === IN_EXTENSION || rfpStatus === CLOSED) {
    status = (
      <>
        <Icon margin="0 4px 0 0" name="Success" size="sm" /> Complete
      </>
    );
  } else if (rfpStatus === COMING_SOON) {
    status = `Coming Soon: ${format(new Date(rfp.launch_date ?? 0), "MMMM d")}`;
  }

  const viewAsScientist = currentUser.role !== 2;

  const isJamesStanton = currentUser.id === 24;

  const canApply =
    rfpStatus === ACTIVE || (rfpStatus === IN_EXTENSION && request.enabled) || isJamesStanton;

  const hasIncompleteProposal = Boolean(
    currentUser.profile_info.proposals?.find(
      (proposal) => proposal.request_id === request.id && !proposal.completed
    )
  );

  let heading = "Share Opportunity";
  if (viewAsScientist) {
    if (canApply) {
      heading = "Submit Proposal";
    } else if (rfpStatus === COMING_SOON) {
      heading = "Follow Opportunity";
    }
  }

  let scientistPrimaryButton: ReactElement | null = null;
  let universityPrimaryButton = (
    <Button iconName="Share" margin="0 0 10px" onClick={handleShare} type="button">
      Share
    </Button>
  );
  if (!request.company.is_partner) {
    scientistPrimaryButton = (
      <Button
        iconName="Open in a new tab"
        onClick={() =>
          "url" in request.company &&
          request.company.url &&
          window.open(request.company.url, "_blank")
        }
        type="button"
      >
        View Site
      </Button>
    );
  } else if (canApply) {
    scientistPrimaryButton = (
      <Button
        data-testid="rfp-page-submit-proposal-button"
        iconName={hasIncompleteProposal && !isJamesStanton ? "Attention" : "Easy Apply"}
        onClick={handleSubmitClick}
        type="button"
      >
        {hasIncompleteProposal && !isJamesStanton ? "Complete Proposal" : "Easy Apply"}
      </Button>
    );
  } else if (rfpStatus === COMING_SOON) {
    scientistPrimaryButton = isUserFollowing ? (
      <FollowingConfirmation>
        <Icon color={COLORS.GREEN} margin="0 4px 0 0" name="Success" />
        You&apos;ll be notified
      </FollowingConfirmation>
    ) : (
      <Button iconName="Bell" margin="10px 0 0" onClick={handleNotify} type="button">
        Notify Me
      </Button>
    );
  } else if (rfpStatus === IN_EXTENSION || rfpStatus === CLOSED) {
    scientistPrimaryButton = (
      <Button iconName="Share" onClick={handleShare} type="button">
        Share
      </Button>
    );
  }

  if (rfp.is_trial) {
    if (rfpStatus === ACTIVE) {
      status = (
        <>
          <StatusCircle margin="0 8px 0 0">
            <Icon color={COLORS.WHITE} name="History" size="sm" />
          </StatusCircle>
          Accepting first 5 proposals
        </>
      );
    } else if (rfpStatus === IN_EXTENSION && request.enabled) {
      heading = "Join Waitlist";
      status = "Get notified if this opportunity reopens.";
      scientistPrimaryButton = (
        <Button iconName="History" onClick={handleJoinWaitlist} type="button">
          Join waitlist
        </Button>
      );
      universityPrimaryButton = scientistPrimaryButton;
    }
  }

  useEffect(() => {
    if (rfp.is_trial && rfpStatus === IN_EXTENSION && request.enabled) {
      setIsJoinWaitlistModalOpen(true);
    }
  }, [rfp.is_trial, rfpStatus]);

  return (
    <>
      <SignUpModal
        company={request.company as CompanyPartial}
        isOpen={isWhiteLabelSignUpModalOpen}
        onClose={() => setIsWhiteLabelSignUpModalOpen(false)}
      />
      <FuelModal isOpen={isFuelModalOpen} onClose={() => setIsFuelModalOpen(false)} />
      <JoinWaitlistModal
        isOpen={isJoinWaitlistModalOpen}
        onClose={() => setIsJoinWaitlistModalOpen(false)}
        handleShare={handleShare}
        request={request}
      />
      <Container isSponsor={currentUser.role === 1}>
        <Heading>{heading}</Heading>
        {!asPreview && <Status>{status}</Status>}
        {viewAsScientist ? scientistPrimaryButton : universityPrimaryButton}
        {viewAsScientist && (
          <>
            {(rfpStatus === COMING_SOON || canApply) && (
              <Button
                iconName="Share"
                margin={`10px 0 ${rfpStatus === COMING_SOON || rfpStatus === ACTIVE ? "10px" : 0}`}
                onClick={handleShare}
                variant="secondary"
                type="button"
              >
                Share
              </Button>
            )}
            {(rfpStatus === COMING_SOON || rfpStatus === ACTIVE) && (
              <AddToCalendarButton
                date={rfpStatus === COMING_SOON ? rfp.launch_date : rfp.deadline}
                description={`https://halo.science/research/${
                  rfp.rfp_program?.identifier ?? "program"
                }/${request.slug}\n\n${withoutTooltip(rfp.background)}`}
                title={`Halo RFP ${rfpStatus === COMING_SOON ? "Launch Date" : "Deadline"}: ${
                  request.title
                }`}
              />
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default RfpCtaContainer;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 2px solid ${COLORS.HALO_BLUE};
  border-radius: 8px;
  padding: 40px 32px 24px;
  margin-bottom: 48px;
  pointer-events: ${({ isSponsor }) => (isSponsor ? "none" : "auto")};
`;
const Heading = styled.div`
  ${FONTS.HEADING_4_SEMIBOLD};
  margin-bottom: 24px;
`;
const Status = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto 24px;
  height: 24px;
  ${FONTS.MEDIUM_2};
`;
const FollowingConfirmation = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
  color: ${COLORS.GREEN};
  ${FONTS.MEDIUM_2};
  margin: 0 auto 10px;
`;
