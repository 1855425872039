import { Request } from "@tsTypes/schema";
import request from "./request";

export const followRequest = async (requestId: number): Promise<Request> => {
  const response = await request.post("/follow_request", {
    request_id: requestId,
  });

  return response.data.followed_request;
};

export const unfollowRequest = async (requestId: number): Promise<void> => {
  await request.post("/unfollow_request", {
    request_id: requestId,
  });
};
