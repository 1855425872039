import { Card, CompanyAvatar, Tag, TruncatedText, XMoreWrapper } from "@components/library";
import { COLORS, FONTS } from "@constants";
import useFollowRequest from "@hooks/useFollowRequest";
import { RootState } from "@redux/store";
import { SegmentEventName } from "@tsTypes/__generated__/enums";
import { ConfidentialCompany, RequestForCard } from "@tsTypes/index";
import { LaunchableRequestStatus, RequestableType } from "@tsTypes/requests";
import { UserRole } from "@tsTypes/users";
import { track } from "@utils/appUtils";
import { getLaunchableRequestStatus, requestPageUrlPath } from "@utils/requestUtils";
import { checkRfpCompanyHidden } from "@utils/rfpUtils";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import RfpCardFooter from "./RfpCardFooter";

interface Props {
  request: RequestForCard;
  isFeatured?: boolean;
  onClick?: (...args: any[]) => any;
  shouldShowUnsubscribeButton?: boolean;
  fromHref?: string;
}

const RfpCard = ({
  request,
  isFeatured = false,
  onClick,
  shouldShowUnsubscribeButton = false,
  fromHref,
}: Props) => {
  const [isCardHovered, setIsCardHovered] = useState(false);

  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);

  const { title, company, disciplines, areas_of_expertise, requestable, requestable_type } =
    request;

  const { handleFollowRequest, isUserFollowing } = useFollowRequest(request.id);

  const handleNotify = () => {
    if (getLaunchableRequestStatus(request) !== LaunchableRequestStatus.COMING_SOON) return;

    handleFollowRequest();

    track(SegmentEventName.Click, {
      ui_component: "Coming soon request card",
      react_component: "RfpCardNew",
      logged_in: false,
    });
  };

  let keywords: string[];
  if (!currentUser?.id) {
    keywords = [...(disciplines ?? []), ...(areas_of_expertise ?? [])];
  } else if (currentUser.role === UserRole.UNIVERSITY_ADMIN) {
    keywords = disciplines;
  } else {
    keywords = areas_of_expertise;
  }
  // Remove duplicates just in case
  keywords = Array.from(new Set(keywords));

  const isComingSoon = getLaunchableRequestStatus(request) === LaunchableRequestStatus.COMING_SOON;
  const isPrivate = checkRfpCompanyHidden(request, currentUser);

  const CardLink = isComingSoon || shouldShowUnsubscribeButton ? DisabledCardLink : ActiveCardLink;

  return (
    <CardLink
      to={{
        pathname: requestPageUrlPath(request),
        fromHref,
      }}
    >
      <Card
        padding="0px"
        showHoverBorder={!isFeatured}
        onClick={onClick ?? handleNotify}
        onMouseEnter={() => setIsCardHovered(true)}
        onMouseLeave={() => setIsCardHovered(false)}
        data-testid="rfp-card"
      >
        {isFeatured && (
          <ImageContainer>
            <ProgramImage
              backgroundImageUrl={
                requestable_type === RequestableType.RFP && requestable.rfp_program?.cover_image.url
              }
              isCardHovered={isCardHovered}
            />
          </ImageContainer>
        )}
        <MainContent isFeatured={isFeatured}>
          <Company data-testid="company-name">
            <CompanyAvatar
              isPrivate={isPrivate}
              imageUrl={"avatar" in company ? company.avatar.url : null}
              size={isFeatured ? "lg" : "sm"}
            />
            {isPrivate ? (
              (company as ConfidentialCompany).private_alias ?? "Private"
            ) : (
              <TruncatedText
                text={"company_name" in company ? company.company_name : ""}
                font={FONTS.SEMIBOLD_1}
                textWidth={`calc(100% - ${isFeatured ? 72 : 48}px)`}
                tooltipWidth="200px"
              />
            )}
          </Company>
          <Title isFeatured={isFeatured} data-testid="title">
            <TruncatedText
              text={title}
              lines={isFeatured ? 3 : 4}
              font={isFeatured ? FONTS.HEADING_5_MEDIUM : FONTS.MEDIUM_1}
            />
          </Title>
          <div data-testid="keywords">
            <XMoreWrapper maxLines={2}>
              {keywords.map((keyword) => (
                <Tag
                  key={`${request.id}-${keyword}`}
                  content={keyword}
                  size="sm"
                  theme={isCardHovered ? "dark" : "neutral"}
                />
              ))}
            </XMoreWrapper>
          </div>
        </MainContent>
        <RfpCardFooter
          request={request}
          isUserFollowing={isUserFollowing ?? false}
          isCardHovered={isCardHovered}
          shouldShowUnsubscribeButton={shouldShowUnsubscribeButton}
        />
      </Card>
    </CardLink>
  );
};

export default RfpCard;

const ActiveCardLink = styled(Link)`
  display: block;
  :visited,
  :link {
    color: ${COLORS.BLACK};
    text-decoration: none;
  }
`;
const DisabledCardLink = styled.div`
  display: block;
  color: ${COLORS.BLACK};
  text-decoration: none;
`;
const ImageContainer = styled.div`
  position: relative;
  height: 168px;
  max-width: 100%;
  color: ${COLORS.WHITE};
  border-radius: 12px 12px 0px 0px;
`;
const ProgramImage = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
  width: 100%;
  background-image: url("${({ backgroundImageUrl }) => backgroundImageUrl}");
  background-position: center;
  background-size: ${({ isCardHovered }) => (isCardHovered ? "105%" : "100%")};
  transition: all 0.5s ease;
  border-radius: 12px 12px 0px 0px;
`;
const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${({ isFeatured }) => (isFeatured ? "290px" : "305px")};
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 24px 24px 16px;
  border-radius: ${({ borderRadius }) => borderRadius};
`;
const Company = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 32px;
  ${FONTS.SEMIBOLD_1};
`;
const Title = styled.div`
  height: ${({ isFeatured }) => (isFeatured ? "84px" : "54px")};
  margin-bottom: auto;
  ${FONTS.MEDIUM_1};
`;
