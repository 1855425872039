import { BlankModal, Icon } from "@components/library";
import Button from "@components/library/Buttons/Button";
import { BlankModalProps } from "@components/library/ModalBases/BlankModal";
import { COLORS, FONTS, WINDOW_DIMENSIONS } from "@constants";
import styled from "styled-components";

const FuelModal = ({ isOpen, onClose }: BlankModalProps) => {
  return (
    <BlankModal onClose={onClose} isOpen={isOpen} width="419px">
      <Top data-testid="fuel-modal">
        <Icon name="Lock" size="lg" />
        <Header>
          Only FUEL members can <br />
          respond to this request.
        </Header>
        <InfoText>
          Future Use of Energy in Louisiana (FUEL) is a coalition of organizations focused on
          positioning Louisiana as a global energy transition leader. Interested in joining FUEL?
        </InfoText>
        <Button
          size="md"
          iconName="Open in a new tab"
          iconPosition="right"
          margin="8px 0 0"
          to="https://fuelouisiana.org/"
          openInNewTab
          isExternalLink
        >
          Learn more about FUEL
        </Button>
      </Top>
      <Bottom>
        Already part of FUEL? <a href="mailto:info@fuelouisiana.org">Contact FUEL for support</a>
      </Bottom>
    </BlankModal>
  );
};

export default FuelModal;

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const Header = styled.h3`
  color: ${COLORS.BLACK};
  ${FONTS.HEADING_5_REGULAR};
  margin: 4px 0px 0px 0px;
`;

const InfoText = styled.p`
  color: ${COLORS.NEUTRAL_500};
  ${FONTS.REGULAR_2};
  text-align: center;
  margin: 16px 0;
`;

const Top = styled.div`
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 48px;
`;

const Bottom = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  flex-direction: column;

  border-top: 1px solid ${COLORS.NEUTRAL_300};

  padding: 24px 48px;

  text-align: center;
  display: inline-block;
  ${FONTS.MEDIUM_2}

  ${MOBILE_MEDIA_QUERY} {
    margin-top: 15px;
    width: 100%;
    color: ${COLORS.NEUTRAL_500};
    ${FONTS.SEMIBOLD_1}
    text-align: left;
  }
`;
