import InviteModal from "@components/InviteModal";
import { RequestPageBanner } from "@components/RequestPageComponents";
import TRLs from "@components/TRLs";
import toast from "@components/Toast";
import TooltipOld from "@components/Tooltip";
import { Button, Icon, Tag, TextLink, Tooltip } from "@components/library";
import RequestAttachmentsContainer from "@components/library/Attachments/RequestAttachmentsContainer";
import { COLORS, FEATURE_FLAGS, FONTS, WINDOW_DIMENSIONS } from "@constants";
import { useSearchParams } from "@hooks/router";
import fallbackApplication from "@images/backgrounds/fallback_application.png";
import { fetchCurrentUser } from "@redux/actions/profileActions";
import { RootState } from "@redux/store";
import { CompanyAsJson, getCompanyBranding } from "@requests/companies";
import { getRfpForRfpPage } from "@requests/rfps";
import { marketplacePath } from "@routes/utils";
import { SegmentEventName } from "@tsTypes/__generated__/enums";
import { RfpStatus } from "@tsTypes/rfps";
import { CompanyBranding } from "@tsTypes/schema";
import { getArrayFromRubyRange, track } from "@utils/appUtils";
import { getRfpProgramName, getRfpStatus } from "@utils/rfpUtils";
import { fixMarkdownNewlines, renderMarkdownText } from "@utils/textUtils";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import styled from "styled-components";
import RequestDiscussion from "../../components/RequestDiscussion/RequestDiscussion";
import WhiteLabelNavbar from "../WhiteLabel/Navbar";
import NavbarPopup from "../WhiteLabel/Navbar/NavbarPopup";
import PreviewRfpPageBanner from "./PreviewRfpPageBanner";
import RfpCtaContainer from "./RfpCtaContainer";
import RfpKeywordsContainer from "./RfpKeywordsContainer";
// @ts-ignore
import { CompanyVerificationStatus } from "@tsTypes/companies.js.erb";
import { RfpPageRequest } from "@tsTypes/index";
import { UserRole } from "@tsTypes/users";
import { t } from "@utils/i18n";
import RequestFAQs from "../../components/RequestFAQs";
import AboutCompany from "./AboutCompany";
import AccessCode from "./AccessCode";
import AccessCodeBanner from "./AccessCodeBanner";
import Benefits from "./Benefits";

interface Props {
  asPreview?: boolean;
  rfpPreviewData?: RfpPageRequest;
  isResponsive?: boolean;
}

export const ACCESS_CODE_PARAM = "access_code";

const RfpPage = ({ asPreview = false, rfpPreviewData, isResponsive = true }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [request, setRequest] = useState<RfpPageRequest>(rfpPreviewData ?? ({} as RfpPageRequest));
  const [companyBranding, setCompanyBranding] = useState<CompanyBranding | null>(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isCommenting, setIsCommenting] = useState(false);

  const [needsAccessCode, setNeedsAccessCode] = useState(false);
  const [accessCodeError, setAccessCodeError] = useState("");
  const [accessCodeRequestData, setAccessCodeRequestData] = useState<{
    company_name: string;
    request_title: string;
  }>();

  const rfp = (rfpPreviewData ?? request).requestable;
  const company = request.company;

  const rfpDiscussionRef = useRef<HTMLDivElement>(null);
  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);

  const dispatch = useDispatch();
  const { identifier, previewHash } = useParams();
  const history = useHistory();
  const { fromHref } = history.location;
  const [searchParams] = useSearchParams();
  const accessCodeParam = searchParams.get(ACCESS_CODE_PARAM);

  const isWhiteLabel = location.pathname.startsWith("/partner");

  const fetchRequest = async (code?: string) => {
    try {
      // RFP Page
      if (!asPreview && !rfpPreviewData) {
        const { request: rfpRequest } = await getRfpForRfpPage(identifier, code ?? accessCodeParam);

        if (!rfpRequest.id) {
          history.push("/");
        }

        document.title = `${rfpRequest.title} | ${rfpRequest.requestable.rfp_program?.name} | Halo`;

        // Fetch company branding for white label
        if (isWhiteLabel && "id" in rfpRequest.company) {
          setCompanyBranding(await getCompanyBranding(rfpRequest.company?.id));
        }

        dispatch(fetchCurrentUser());

        track(SegmentEventName.View, {
          request_id: rfpRequest.id,
          requestable: {
            requestable_id: rfpRequest.id,
            requestable_type: rfpRequest.requestable_type,
          },
          react_component: "RfpPage",
        });

        if (searchParams.get("saved")) {
          toast.success(
            <p>
              Saved! You can find your draft in&nbsp;
              <Link to="/my_proposals" style={{ textDecoration: "underline", color: "white" }}>
                My proposals
              </Link>
              .
            </p>
          );
        }

        if (code) {
          searchParams.set(ACCESS_CODE_PARAM, code);
          history.replace({ search: searchParams.toString() });
        }
        setNeedsAccessCode(false);

        setRequest(rfpRequest);
        setIsLoading(false);
        return;
      }

      // RFP Preview Page
      if (asPreview && !rfpPreviewData) {
        const decodedRfpIdentifier = atob(previewHash);

        const { request: rfpRequest } = await getRfpForRfpPage(decodedRfpIdentifier, previewHash);

        if (!rfpRequest.id) {
          history.push("/");
        }

        setRequest(rfpRequest);
        setIsLoading(false);
        return;
      }

      // RFP Form Preview
      if (asPreview && rfpPreviewData) {
        setIsLoading(false);
      }
    } catch (e) {
      if (e.response.status === 401) {
        if (needsAccessCode) {
          setAccessCodeError("Access code incorrect.");
        } else {
          setNeedsAccessCode(true);
          setAccessCodeRequestData(e.response.data);
        }
      }
    }
  };

  useEffect(() => {
    fetchRequest();
  }, []);

  useLayoutEffect(() => {
    let animationFrameId: number;

    const checkAndScroll = () => {
      if (window.location.hash === "#qa" && rfpDiscussionRef.current) {
        rfpDiscussionRef.current.scrollIntoView({ behavior: "smooth" });
      } else {
        animationFrameId = requestAnimationFrame(checkAndScroll);
      }
    };

    checkAndScroll();

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [rfpDiscussionRef]);

  if (needsAccessCode) {
    return (
      <AccessCode
        onSubmit={fetchRequest}
        error={accessCodeError}
        requestData={accessCodeRequestData}
      />
    );
  }

  if (isLoading) {
    return <Loader inline="centered" />;
  }

  const showRfpDiscussionBanner =
    getRfpStatus(request) !== RfpStatus.IN_EXTENSION && rfp.is_discussion_enabled;
  const trlRange = rfp.trl_range ? getArrayFromRubyRange(rfp.trl_range) : null;

  const shouldShowAccessCodeBanner =
    !asPreview &&
    request.visibility.none &&
    request.access_code &&
    (currentUser.role === UserRole.SPONSOR || currentUser.is_admin);

  const isConfidential = request?.confidential || rfpPreviewData?.confidential;

  return (
    <>
      <InviteModal
        open={isShareModalOpen}
        requestId={request.id}
        closeModal={() => setIsShareModalOpen(false)}
        trackAndToastHandler={() => toast.success("RFP shared!")}
        inviteMessage=""
        inviteUrl="/notifications/share_rfp"
        modalHeader={`Share this opportunity with ${
          currentUser.role === 2 ? "faculty" : "colleagues"
        }`}
        modalSubheader={`Your ${
          currentUser.role === 2 ? "faculty" : "colleague(s)"
        } will receive an email with a link to this opportunity`}
        inviteSubject={`${currentUser.first_name} ${currentUser.last_name} shared a funding opportunity with you.`}
        typeOfInvite="shareRFP"
      />
      {isWhiteLabel && company && "company_name" in company && (
        <WhiteLabelNavbar accent company={company as CompanyAsJson} branding={companyBranding}>
          <NavbarExternalLink href={company.url}>Visit {company.company_name}</NavbarExternalLink>
          {currentUser.id ? (
            <NavbarPopup branding={companyBranding} />
          ) : (
            <NavbarLink to="/login">Sign in</NavbarLink>
          )}
        </WhiteLabelNavbar>
      )}
      {asPreview && !rfpPreviewData && <PreviewRfpPageBanner />}
      {shouldShowAccessCodeBanner && <AccessCodeBanner accessCode={request.access_code!} />}
      <Container asPreview={asPreview} isResponsive={isResponsive} data-testid="rfp-page-main">
        {FEATURE_FLAGS.COMMERCIAL_APPLICATIONS ? (
          <WidthContainer>
            {!asPreview && (
              <TextLink
                to={fromHref ?? marketplacePath("requests")}
                font={FONTS.MEDIUM_2}
                margin="0 0 24px"
              >
                <Icon name="Chevron Left" margin="0 0 0 -8px" />
                Back to Opportunities
              </TextLink>
            )}
            <RequestPageBanner request={request} asPreview={asPreview} />
          </WidthContainer>
        ) : (
          <HeroContainer
            asPreview={asPreview}
            backgroundImageUrl={rfp.rfp_program?.banner_image?.url ?? fallbackApplication}
          >
            <HeroContent isResponsive={isResponsive}>
              {asPreview || isWhiteLabel ? (
                <br />
              ) : (
                <TextLink to={fromHref ?? marketplacePath("requests")} font={FONTS.MEDIUM_2}>
                  <Icon name="Chevron Left" margin="0 0 0 -8px" />
                  Back to Opportunities
                </TextLink>
              )}
              <TitleSection>
                {!isWhiteLabel && (
                  <CompanyContainer isResponsive={isResponsive} data-testid="company-name">
                    {isConfidential ? (
                      <>
                        {company.private_alias ?? "Private Company"}&nbsp;&nbsp;
                        <TooltipOld
                          text="The sponsor name will be revealed if your proposal is advanced."
                          linkText="Learn more."
                          linkUrl="https://knowledge.halo.science/why-are-sponsors-private-for-some-rfps"
                        />
                      </>
                    ) : (
                      "company_name" in company && (
                        <>
                          {company.avatar?.url && (
                            <CompanyAvatar src={company.avatar.url} alt="Company logo" />
                          )}
                          <TextLink to={`/company/${company.identifier}`} font={FONTS.SEMIBOLD_1}>
                            {company.company_name}
                          </TextLink>
                          {company.verification_status === CompanyVerificationStatus.VERIFIED && (
                            <Icon name="Verified" margin="0 0 0 8px" color={COLORS.HALO_BLUE} />
                          )}
                        </>
                      )
                    )}
                  </CompanyContainer>
                )}
                <Title isResponsive={isResponsive} data-testid="rfp-title">
                  {request.title}
                </Title>
                <Application>
                  {request.visibility.any && (
                    <TextLink
                      to={`/research/${rfp.rfp_program?.identifier}`}
                      font={FONTS.TAG_BOLD_2}
                    >
                      <Icon
                        name={getRfpProgramName(rfp.rfp_program?.identifier)}
                        margin="0 4px 0 0"
                      />
                      {rfp.rfp_program?.name}
                    </TextLink>
                  )}
                </Application>
              </TitleSection>
            </HeroContent>
          </HeroContainer>
        )}
        <MainContent isResponsive={isResponsive}>
          <div>
            {showRfpDiscussionBanner && (
              <QuestionCTA isResponsive={isResponsive}>
                <div>
                  <Heading>What questions do you have about this RFP?</Heading>
                  <div>
                    Get them answered by the team at{" "}
                    {!isConfidential && "company_name" in company
                      ? company.company_name
                      : "the company"}
                    .
                  </div>
                </div>
                <Button
                  width="max-content"
                  onClick={() => {
                    rfpDiscussionRef.current?.scrollIntoView({ behavior: "smooth" });
                    if (rfp.is_discussion_enabled && currentUser.role === 0) {
                      setIsCommenting(true);
                    }
                  }}
                >
                  Ask your question
                </Button>
              </QuestionCTA>
            )}
            <Heading>{t("pages.rfp_page.headings.background")}</Heading>
            <div data-testid="rfp-background">
              {renderMarkdownText(fixMarkdownNewlines(rfp.background))}
            </div>
            <Heading>{t("pages.rfp_page.headings.looking_for")}</Heading>
            <BulletedSection data-testid="rfp-looking-for">
              {renderMarkdownText(rfp.looking_for)}
            </BulletedSection>
            <RequestAttachmentsContainer
              heading={<Subheading>{t("pages.rfp_page.headings.attachments")}</Subheading>}
              attachments={request.request_attachments}
            />
            {/* These fields are stored as strings, and the first 3 characters just render a bullet point */}
            {Boolean(request.request_solutions?.length) && (
              <>
                <Subheading>{t("pages.rfp_page.headings.solutions_of_interest")}</Subheading>
                <BulletedSection data-testid="rfp-solutions-of-interest">
                  {renderMarkdownText(
                    request.request_solutions.map((solution) => `* ${solution.name}`).join("\n")
                  )}
                </BulletedSection>
              </>
            )}
            {Boolean(request.request_requirements?.length) && (
              <>
                <Subheading>{t("pages.rfp_page.headings.must_have_requirements")}</Subheading>
                <BulletedSection data-testid="rfp-must-have-requirements">
                  {renderMarkdownText(
                    request.request_requirements
                      .map((requirement) => `* ${requirement.description}`)
                      .join("\n")
                  )}
                </BulletedSection>
              </>
            )}

            {(rfp.preferred_requirements?.length ?? 0) > 2 && (
              <>
                <Subheading>{t("pages.rfp_page.headings.preferred_requirements")}</Subheading>
                <BulletedSection data-testid="rfp-nice-to-have-requirements">
                  {renderMarkdownText(rfp.preferred_requirements)}
                </BulletedSection>
              </>
            )}
            {(rfp.out_of_scope?.length ?? 0) > 2 && (
              <>
                <Subheading>{t("pages.rfp_page.headings.out_of_scope")}</Subheading>
                <BulletedSection data-testid="rfp-out-of-scope">
                  {renderMarkdownText(rfp.out_of_scope)}
                </BulletedSection>
              </>
            )}
            {trlRange ? (
              <TRLsContainer>
                <Subheading>
                  {t("pages.rfp_page.headings.trl")}
                  <Tag
                    content={
                      <TrlTagContent>
                        {trlRange[0] === trlRange[1]
                          ? `Level ${trlRange[0]}`
                          : `Levels ${trlRange[0]}-${trlRange[1]}`}
                        <Tooltip
                          content="Technology readiness levels help describe the maturity of solutions. An organization may seek solutions that fall only within a specified range of TRLs."
                          tooltipWidth="410px"
                        >
                          <Icon name="Info" size="xs" color={COLORS.NEUTRAL_400} />
                        </Tooltip>
                      </TrlTagContent>
                    }
                    size="md"
                    margin="0 0 0 16px"
                  />
                </Subheading>
                <TRLs trlRange={trlRange} />
              </TRLsContainer>
            ) : null}
            <Benefits
              partnershipModels={rfp.partnership_models ?? []}
              fundingBenefits={request.funding_benefits}
              nonMonetaryBenefits={request.non_monetary_benefits}
              isResponsive={isResponsive}
            />
            {!isConfidential && (
              <AboutCompany request={request as any} isResponsive={isResponsive} />
            )}
            {rfp.is_discussion_enabled && (
              <RequestDiscussion
                request={request}
                ref={rfpDiscussionRef}
                isCommenting={isCommenting}
                setIsCommenting={setIsCommenting}
                setIsShareModalOpen={setIsShareModalOpen}
              />
            )}
          </div>
          <div>
            <RfpCtaContainer
              request={request}
              asPreview={asPreview}
              setIsShareModalOpen={setIsShareModalOpen}
            />
            <RfpKeywordsContainer request={request} />
            {request.visibility.any && <RequestFAQs request={request} />}
          </div>
        </MainContent>
      </Container>
    </>
  );
};

export default RfpPage;

const NavbarExternalLink = styled.a`
  ${FONTS.MEDIUM_1}
  color: ${COLORS.WHITE};
  margin-right: 32px;

  &:hover {
    color: ${COLORS.WHITE};
  }
`;
const NavbarLink = styled(Link)`
  ${FONTS.MEDIUM_1}
  color: ${COLORS.WHITE};

  &:hover {
    color: ${COLORS.WHITE};
  }
`;
const Container = styled.div`
  pointer-events: ${({ asPreview }) => (asPreview ? "none" : "auto")};
  ${({ isResponsive }) => !isResponsive && `width: ${WINDOW_DIMENSIONS.BASE_WINDOW_WIDTH}px;`}
`;
const HeroContainer = styled.div`
  position: relative;
  height: 420px;
  background: url(${({ backgroundImageUrl }) => backgroundImageUrl});
  ${({ asPreview }) => asPreview && `background: ${COLORS.BLUE_LIGHT_300};`}
  background-size: cover;
`;
const HeroContent = styled.div`
  display: grid;
  grid-template-rows: fit-content(100%) 1fr;
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 24px 84px;
  ${({ isResponsive }) =>
    isResponsive &&
    `
      ${WINDOW_DIMENSIONS.MOBILE_MEDIA_QUERY} {
        padding: 24px 32px;
      }
  `}
`;
const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;
const CompanyContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  ${FONTS.SEMIBOLD_1};
  ${({ isResponsive }) =>
    isResponsive &&
    `
      ${WINDOW_DIMENSIONS.MOBILE_MEDIA_QUERY} {
        margin-top: 32px;
      }
  `}
`;
const CompanyAvatar = styled.img`
  height: 24px;
  max-width: 40px;
  object-fit: contain;
  margin-right: 4px;
  ${FONTS.REGULAR_3};
`;
const Title = styled.div`
  ${FONTS.HEADING_1_SEMIBOLD};
  margin-bottom: 16px;
  width: calc(100% - 190px);
  ${({ isResponsive }) =>
    isResponsive &&
    `
      ${WINDOW_DIMENSIONS.DESKTOP_MEDIA_QUERY} {
        width: calc(100% - 80px);
      }
      ${WINDOW_DIMENSIONS.TABLET_LANDSCAPE_MEDIA_QUERY} {
        width: 100%;
      }
      ${WINDOW_DIMENSIONS.MOBILE_MEDIA_QUERY} {
        ${FONTS.HEADING_3_SEMIBOLD};
      }
  `}
`;
const Application = styled.div`
  display: flex;
  align-items: center;
  letter-spacing: 2px;
  text-transform: uppercase;
`;
const QuestionCTA = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 64px;
  background: ${COLORS.BLUE_LIGHT_250};
  padding: 32px 64px 32px 48px;
  border-radius: 12px;
  ${FONTS.REGULAR_1}
  ${({ isResponsive }) =>
    isResponsive &&
    `
      ${WINDOW_DIMENSIONS.DESKTOP_MEDIA_QUERY} {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
      }
      ${WINDOW_DIMENSIONS.EXTRA_WIDE_MEDIA_QUERY} {
        width: max-content;
      }
  `}
`;
const MainContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 350px;
  gap: 190px;
  padding: 84px 84px 100px;
  & p {
    ${FONTS.REGULAR_1};
    margin: 0;
  }
  ${({ isResponsive }) =>
    isResponsive &&
    `
      ${WINDOW_DIMENSIONS.DESKTOP_MEDIA_QUERY} {
        gap: 80px;
      }
      ${WINDOW_DIMENSIONS.TABLET_LANDSCAPE_MEDIA_QUERY} {
        display: flex;
        flex-direction: column-reverse;
        gap: 64px;
      }
      ${WINDOW_DIMENSIONS.MOBILE_MEDIA_QUERY} {
        padding: 84px 32px 100px;
      }
  `}
`;
const Heading = styled.div`
  ${FONTS.HEADING_4_SEMIBOLD};
  margin-bottom: 24px;
  &:not(:first-of-type) {
    margin-top: 64px;
  }
`;
const Subheading = styled.div`
  display: flex;
  align-items: center;
  ${FONTS.SEMIBOLD_1};
  margin: 24px 0 8px;
`;
const BulletedSection = styled.div`
  & ul {
    padding-left: 4px;
  }
`;
// Used in RequestForStartupPage
export const TRLsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 12px;
`;
export const TrlTagContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

// TODO: (TGAP4) HALO-1645 Remove this component and use PageContainer after COMMERCIAL_APPLICATIONS is enabled
const WidthContainer = styled.div`
  padding: 24px ${WINDOW_DIMENSIONS.PAGE_SIDE_PADDING}px 0;
  border-top: 1px solid ${COLORS.NEUTRAL_100};
  ${WINDOW_DIMENSIONS.MOBILE_MEDIA_QUERY} {
    padding: 24px 32px 0;
  }
`;
