import { lazyWithRetry } from "@utils/appUtils";
import { Redirect, Route } from "react-router-dom";
import { seo_request_for_proposal_path } from "./routes.js";
import { formatPathForRouter } from "./utils";

const RfpPage = lazyWithRetry(() => import("../views/RFPPage"));

const routes = [
  <Route
    key="rfp_routes/1"
    path={formatPathForRouter(seo_request_for_proposal_path)}
    render={({ match, location }) => (
      <Redirect to={`/request_for_solutions/${match.params.identifier}${location.search}`} />
    )}
  />,
  <Route key="rfp_routes/2" exact path="/request_for_solutions/:identifier" component={RfpPage} />,
  <Route key="rfp_routes/3" exact path="/preview-rfp/:previewHash">
    <RfpPage asPreview />
  </Route>,
];

export default routes;
