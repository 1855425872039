import { StyledLink } from "@components/Footer/Footer";
import { Button } from "@components/library";
import { toggleLoginModal } from "@redux/actions/profileActions";
import { RootState } from "@redux/store";
import { SegmentEventName } from "@tsTypes/__generated__/enums";
import { UserRole } from "@tsTypes/users";
import { track } from "@utils/appUtils";
import { setPrevUrlCookie } from "@utils/authUtils";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

interface Props {
  type?: "button" | "link";
}

const CANNY_APP_ID = "660af386fa664b82e994fd67";

const FeatureRequestButton = ({ type = "link" }: Props) => {
  const { id, name, email, image, role, profile_info, created_at, roles } = useSelector(
    (state: RootState) => state.profiles.currentUser
  );
  const isSponsor = role === UserRole.SPONSOR;
  const isUniversityAdmin = role === UserRole.UNIVERSITY_ADMIN;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!id) return;

    let userType: "Scientist" | "Sponsor" | "University Admin" = "Scientist";
    if (isSponsor) userType = "Sponsor";
    else if (isUniversityAdmin) userType = "University Admin";

    window.Canny?.("identify", {
      appID: CANNY_APP_ID,
      user: {
        id,
        name,
        email,
        avatarURL: image.url,
        created: new Date(created_at).toISOString(),
        ...(isSponsor && {
          companies: [
            {
              id: profile_info.company_id,
              name: profile_info.company_name,
            },
          ],
        }),
        // Canny prettifies custom field names in the UI using snake_case (or kebab-case)
        customFields: {
          user_type: userType,
          ...(role === UserRole.SCIENTIST && {
            is_startup_scientist: profile_info.is_startup_scientist,
            scientist_affiliation: profile_info.location,
            scientist_number_of_proposals: (profile_info.proposals || []).filter(
              (proposal) => proposal.completed && !proposal.deleted_at
            ).length,
          }),
          ...(isSponsor && {
            is_company_admin: profile_info.is_admin,
            is_company_super_admin: profile_info.is_super_admin,
            sponsor_license_type: profile_info.license,
            sponsor_number_of_review_teams: roles.filter(
              (_role) => _role.resource_type === "Request"
            ).length,
          }),
          ...(isUniversityAdmin && {
            university_admin_affiliation: profile_info.university,
            university_admin_number_of_invites: profile_info.invited_users_count,
            university_admin_number_of_accepted_invites: profile_info.accepted_users_count,
          }),
        },
      },
    });
  }, [id]);

  const handleClick = () => {
    track(SegmentEventName.Click, {
      ui_component: `Feature Request Button (${type === "button" ? "Splash Page" : "Footer"})`,
    });

    if (!id) {
      setPrevUrlCookie(window.location.pathname);
      dispatch(
        toggleLoginModal({
          open: true,
          previousAction: "submit-feature-request",
        })
      );
      return;
    }

    const authenticatedLink = window.Canny?.(
      "authenticateCannyLink",
      isSponsor
        ? "https://halo-science.canny.io/feature-requests"
        : "https://halo-science.canny.io/scientists-startups-feature-requests"
    );
    window.open(authenticatedLink, "_blank");
  };

  return type === "button" ? (
    <Button onClick={handleClick}>Submit feature request</Button>
  ) : (
    <LinkContainer>
      <StyledLink as="button" onClick={handleClick}>
        Feature requests
      </StyledLink>
    </LinkContainer>
  );
};

export default FeatureRequestButton;

const LinkContainer = styled.div`
  line-height: 1;
  button {
    padding: 0;
    background: none;
    border: none;
    &:focus {
      outline: none;
    }
  }
`;
