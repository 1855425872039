import toast from "@components/Toast/Toast";
import { useSearchParams } from "@hooks/router";
import { setCurrentUser, toggleLoginModal } from "@redux/actions/profileActions";
import { RootState } from "@redux/store";
import { followRequest } from "@requests/userFollowedRequests";
import { useMutation } from "@tanstack/react-query";
import { UserRole } from "@tsTypes/users";
import { setPrevUrlCookie } from "@utils/authUtils";
import { toastErrorHandler } from "@utils/requests";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const NOTIFY_SEARCH_PARAM = "notify";

const useFollowRequest = (requestId: number, shouldSkipFollowOnRedirect = false) => {
  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const isUserFollowing = (currentUser.profile_info.followed_request_ids ?? []).includes(requestId);

  const { mutate, isLoading } = useMutation({
    mutationFn: () => followRequest(requestId),
    onSuccess: (followedRequest) => {
      dispatch(
        setCurrentUser({
          ...currentUser,
          profile_info: {
            ...currentUser.profile_info,
            followed_request_ids: [
              ...currentUser.profile_info.followed_request_ids,
              followedRequest.id,
            ],
          },
        })
      );

      toast.success(
        <p>
          You&apos;ll be notified when applications open for {followedRequest.title}.{" "}
          <Link to="/account/?q=following" style={{ textDecoration: "underline", color: "white" }}>
            View your followed opportunities.
          </Link>
        </p>
      );
    },
    onError: toastErrorHandler,
  });

  useEffect(() => {
    if (
      searchParams.get(NOTIFY_SEARCH_PARAM) === String(requestId) &&
      currentUser.id &&
      !isUserFollowing &&
      !shouldSkipFollowOnRedirect
    ) {
      mutate({});
      searchParams.delete(NOTIFY_SEARCH_PARAM);
      setSearchParams(searchParams);
    }
  }, []);

  const handleFollowRequest = () => {
    if (currentUser.role === UserRole.SPONSOR || isUserFollowing || isLoading) {
      return;
    }

    if (currentUser.id) {
      mutate({});
    } else {
      searchParams.set("notify", String(requestId));
      setPrevUrlCookie(window.location.pathname + "?" + searchParams.toString());
      dispatch(toggleLoginModal({ open: true }));
    }
  };

  return { handleFollowRequest, isUserFollowing };
};

export default useFollowRequest;
